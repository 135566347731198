import styles from "./deposit-crypto-content.module.scss";
import {useState} from "react";
import {numberToPrice} from "../../../../utils/deposits/common";
import {clsx} from "clsx";
import {ReactComponent as BinanceIcon} from "../../../../assets/icons/deposits/binance.svg";
import {ReactComponent as CoinbaseIcon} from "../../../../assets/icons/deposits/coinbase.svg";
import {ReactComponent as WalletIcon} from "../../../../assets/icons/deposits/wallet.svg";

const DepositCryptoContent = ({ handleExchangeDeposit, handleDirectDeposit }) => {
  const currency = "EUR";
  const amountOptions = [0.1, 1, 5, 20, 100];
  const exchanges = [
    {
      name: "binance",
      allowed: true,
      icon: <BinanceIcon />,
    },
    {
      name: "coinbase",
      allowed: true,
      icon: <CoinbaseIcon />,
    },
  ];

  const [amount, setAmount] = useState(amountOptions[1]);

  return <div className={styles.container}>
    <div className={styles.decisionSection}>
      <div className={styles.sectionHeading}>
        <div className={styles.orderingCircle}>
          1
        </div>
        <div className={styles.textContainer}>
          How much would you like to add to your bankroll?
        </div>
      </div>
      <div className={styles.sectionContent}>
        {amountOptions.map(value => <div
          className={clsx(styles.amountOption, value === amount && styles.activeOption)}
          onClick={() => setAmount(value)}
          key={`amount-${value}`}
        >
          {numberToPrice(value, currency, value < 1 ? 2 : 0)}
        </div>)}
      </div>
    </div>
    <div className={styles.decisionSection}>
      <div className={styles.sectionHeading}>
        <div className={styles.orderingCircle}>
          2
        </div>
        <div className={styles.textContainer}>
          Choose your exchange or connect wallet
        </div>
      </div>
      <div className={styles.sectionContent}>
        {exchanges.map(exchangeInfo => <div
          className={clsx(styles.exchangeOptionContainer, !exchangeInfo.allowed && styles.notAllowed)}
          onClick={() => handleExchangeDeposit(exchangeInfo.name, amount)}
          key={`exchange-${exchangeInfo.name}`}
        >
          <div className={styles.icon}>
            {exchangeInfo.icon}
          </div>
          <div className={styles.name}>
            {exchangeInfo.name}
          </div>
        </div>)}
        <div
          className={styles.exchangeOptionContainer}
          onClick={() => handleDirectDeposit(amount)}
        >
          <div className={styles.icon}>
            <WalletIcon />
          </div>
          <div className={styles.name}>
            wallet
          </div>
        </div>
      </div>
    </div>
  </div>;
};

export default DepositCryptoContent;
